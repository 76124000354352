import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/pl'
import 'dayjs/locale/en'

export default defineNuxtPlugin(() => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(advancedFormat)
})

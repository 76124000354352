const pl = [
  'albo',
  'ale',
  'bez',
  'czy',
  'lecz',
  'lub',
  'nad',
  'nie',
  'niech',
  'pod',
  'przez',
  'tak',
  'tylko',
  'więc'
]

const en = [
  'a',
  'and',
  'as',
  'but',
  'for',
  'if',
  'in',
  'nor',
  'of',
  'or',
  'so',
  'the',
  'till',
  'to',
  'us',
  'yet'
]

/**
 * Removes hanging conjunctions from text, by adding &nbsp;
 * @param inputText
 */

export default function removeHangingConjunctions(inputText?: string) {
  if (!inputText) return ''
  const hardSpace = '&nbsp;'
  const softSpace = ' '
  const constantNbsp = [...en, ...pl]

  return inputText
    .split(' ')
    .reduce(
      (acc, word) =>
        (word.length <= 2 && word.length > 0 && !word.includes('<')) ||
        constantNbsp.includes(word.toLocaleLowerCase()) ||
        /^&.+;$/.test(word)
          ? acc + word + hardSpace
          : acc + word + softSpace,
      ''
    )
    .trim()
}

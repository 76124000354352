<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'

import { useHeaderSettings } from '@/state/headerSettings'
import { useWebsiteSettings } from '@/state/websiteSettings'

await Promise.all([useWebsiteSettings(), useHeaderSettings()])

const { locale } = useI18n()
dayjs.locale(locale.value)
</script>

<template>
  <NuxtLayout>
    <div v-viewport-animated="{ mode: 'fade-translate' }" class="grid">
      <WidgetHeading
        class="title"
        is-first
        is-big
        :content="t('page-doesnt-exist')"
      />
      <div class="background">
        <OutlineText class="outline outline-left" text="404" />
        <OutlineText class="outline outline-right" text="404" />
        <OutlineText class="outline outline-bottom" text="404" />
      </div>
      <BaseButton
        :tag="NuxtLink"
        :to="localePath('/')"
        class="button wildwaffle"
        color="primary"
        @click.prevent.capture="handleBack"
      >
        {{ t('back-to-home') }}
      </BaseButton>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { NuxtLink } from '#components'
import { useWebsiteSettings } from '@/state/websiteSettings'

withDefaults(
  defineProps<{
    error: Record<PropertyKey, unknown>
  }>(),
  {
    error: () => ({ statusCode: 500 })
  }
)

const { t } = useI18n()
const localePath = useLocalePath()
const handleBack = () => clearError({ redirect: useLocalePath()('/') })

await useWebsiteSettings()
</script>

<style lang="scss" scoped>
.title,
.button,
.background {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
}

.background {
  position: relative;
}

.outline {
  position: absolute;
  width: rem(400px);

  @include media-up(lg) {
    width: 800px;
  }
}

.outline-left {
  top: rem(200px);
  left: rem(-200px);
  transform: rotate(15deg);

  @include media-up(lg) {
    top: rem(100px);
    left: rem(-300px);
  }
}

.outline-right {
  right: rem(-100px);
  transform: rotate(-23deg);

  @include media-up(lg) {
    transform: rotate(-13deg);
  }
}

.outline-bottom {
  right: rem(-200px);
  bottom: rem(-550px);
  transform: rotate(-120deg);

  @include media-up(lg) {
    bottom: rem(-860px);
    left: rem(200px);
  }
}

.button {
  width: fit-content;
  margin-top: rem(253px);
  margin-right: auto;
  margin-bottom: rem(70px);
  margin-left: auto;

  @include media-up(md) {
    margin-top: rem(366px);
    margin-bottom: rem(132px);
  }

  @include media-up(lg) {
    margin-top: rem(306px);
    margin-bottom: rem(197px);
  }
}
</style>

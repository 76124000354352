import VueGtag from 'vue-gtag'
import { Router } from 'vue-router'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  if (config.public.GTAG_ID) {
    nuxtApp.vueApp.use(
      VueGtag,
      {
        config: {
          id: config.public.GTAG_ID
        }
      },
      nuxtApp.$router as Router
    )
  }
})

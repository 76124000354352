<template>
  <button @click="openPopup">
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{ url: string | null }>()

const openPopup = () => {
  // @ts-ignore
  Calendly.initPopupWidget({ url: props.url })
}

useHead(
  {
    link: [
      {
        id: 'calendly-style',
        rel: 'stylesheet',
        href: 'https://assets.calendly.com/assets/external/widget.css',
        tagPosition: 'bodyClose'
      }
    ],
    script: [
      {
        defer: true,
        id: 'calendly-script',
        src: 'https://assets.calendly.com/assets/external/widget.js'
      }
    ]
  },
  { mode: 'client' }
)
</script>

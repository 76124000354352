import { Ref } from 'vue'

import { PublicSiteSettings } from '@/service/__generated-api'

// Ref.: https://v3.nuxtjs.org/guide/features/state-management/
export const useWebsiteSettings = async (): Promise<
  Ref<PublicSiteSettings | null>
> => {
  const settings = useState<PublicSiteSettings | null>(
    'website-settings',
    () => null
  )

  if (!settings.value) {
    settings.value = await useApi().publicCoreSettingsRetrieve()
  }
  return settings
}

import { default as _91slug_93BzaHAB5h4MMeta } from "/app/pages/[category]/[slug].vue?macro=true";
import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _91slug_931Kp94TPaydMeta } from "/app/pages/artist/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93h6ecm9GkHAMeta } from "/app/pages/news/[slug].vue?macro=true";
import { default as artistOJ4Re3xFh7Meta } from "/app/pages/preview/artist.vue?macro=true";
import { default as newslJeNrHnHA0Meta } from "/app/pages/preview/news.vue?macro=true";
import { default as simple_45page1iE2CAJyY0Meta } from "/app/pages/preview/simple-page.vue?macro=true";
export default [
  {
    name: "category-slug___pl",
    path: "/:category()/:slug()",
    component: () => import("/app/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___en",
    path: "/en/:category()/:slug()",
    component: () => import("/app/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___pl",
    path: "/:slug()",
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "artist-slug___pl",
    path: "/artysta/:slug",
    component: () => import("/app/pages/artist/[slug].vue").then(m => m.default || m)
  },
  {
    name: "artist-slug___en",
    path: "/en/artist/:slug",
    component: () => import("/app/pages/artist/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug___pl",
    path: "/news/:slug",
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "preview-artist___pl",
    path: "/podglad/artysta",
    meta: artistOJ4Re3xFh7Meta || {},
    component: () => import("/app/pages/preview/artist.vue").then(m => m.default || m)
  },
  {
    name: "preview-artist___en",
    path: "/en/preview/artist",
    meta: artistOJ4Re3xFh7Meta || {},
    component: () => import("/app/pages/preview/artist.vue").then(m => m.default || m)
  },
  {
    name: "preview-news___pl",
    path: "/podglad/news",
    meta: newslJeNrHnHA0Meta || {},
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: "preview-news___en",
    path: "/en/preview/news",
    meta: newslJeNrHnHA0Meta || {},
    component: () => import("/app/pages/preview/news.vue").then(m => m.default || m)
  },
  {
    name: "preview-simple-page___pl",
    path: "/podglad/podstrona",
    meta: simple_45page1iE2CAJyY0Meta || {},
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  },
  {
    name: "preview-simple-page___en",
    path: "/en/preview/simple-page",
    meta: simple_45page1iE2CAJyY0Meta || {},
    component: () => import("/app/pages/preview/simple-page.vue").then(m => m.default || m)
  }
]
<template>
  <span v-if="placeholder">
    <slot>{{ link.label }}</slot>
  </span>
  <NuxtLink
    v-else-if="route"
    v-bind="{ to: route, ...(link.target_blank && TARGET_BLANK) }"
  >
    <slot>
      {{ link.label }}
    </slot>
  </NuxtLink>
  <CalendlyButton v-else-if="link.anchor_type === 'calendly'" :url="href">
    <slot>
      {{ link.label }}
    </slot>
  </CalendlyButton>
  <a v-else :href="href" v-bind="{ ...(link.target_blank && TARGET_BLANK) }">
    <slot>
      {{ link.label }}
    </slot>
  </a>
</template>

<script lang="ts" setup>
import { PublicLink, AnchorTypeEnum } from '@/service/__generated-api'

const { t } = useI18n()

type AttachmentAnchor = {
  anchor_type: 'attachment'
  attachment_file: string
  slug: ''
}
type UrlAnchor = { anchor_type: 'url' | 'calendly'; url: string; slug: '' }
type SlugAnchor = {
  anchor_type: 'simplepage' | 'news' | 'artist'
  slug: string
  category?: string
}

type LinkAnchors = AttachmentAnchor | UrlAnchor | SlugAnchor
type Link = LinkAnchors & Partial<PublicLink>

const props = defineProps<{
  link: Link | PublicLink
  placeholder?: boolean
}>()

const extractedLabel = ref('')

const TARGET_BLANK = computed(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  'aria-label': `${extractedLabel.value} - ${t('a11y.opening-in-new-tab')}`
}))

onMounted(() => {
  if (!props.link.label) return

  extractedLabel.value =
    new DOMParser().parseFromString(props.link.label, 'text/html')
      .documentElement.textContent ?? ''
})

const href = computed(() =>
  props.link.anchor_type === 'attachment'
    ? props.link.attachment_file
    : props.link.anchor_type === 'url' || props.link.anchor_type === 'calendly'
    ? props.link.url
    : ''
)

const route = computed(() => {
  const { anchor_type, slug, widget, category } = props.link
  const isHomePage = slug === 'home'
  const hasCategory = !!category

  const routeNameByType: Partial<Record<AnchorTypeEnum, string>> = {
    simplepage: isHomePage ? 'index' : hasCategory ? 'category-slug' : 'slug',
    news: 'news-slug',
    artist: 'artist-slug'
  }

  const name = routeNameByType[anchor_type]

  if (name) {
    const params:
      | undefined
      | { slug: string }
      | { category: string; slug: string } = isHomePage
      ? undefined
      : hasCategory
      ? { category, slug }
      : { slug }
    const hash = (widget && `#widget-${widget}`) ?? ''

    return useLocalePath()({ name, params, hash })
  }
})
</script>

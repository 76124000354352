import { defineNuxtPlugin } from '#app/nuxt'
import { LazyWidgetsArticleListWidget, LazyWidgetsArtistsListWidget, LazyWidgetsBannerWithTextAndCtaWidget, LazyWidgetsBlockquoteWidget, LazyWidgetsBoxWithTextWidget, LazyWidgetsBulletListWidget, LazyWidgetsContactFormWidget, LazyWidgetsContactsWidget, LazyWidgetsCtaButtonWidget, LazyWidgetsFaqWidget, LazyWidgetsGalleryWidget, LazyWidgetsImageTextListWidget, LazyWidgetsImageWidget, LazyWidgetsImageWithAccordionsWidget, LazyWidgetsImageWithMultipleLinksWidget, LazyWidgetsImageWithTextWidget, LazyWidgetsImageWithTilesWidget, LazyWidgetsLatestNewsWidget, LazyWidgetsLineUpWidget, LazyWidgetsNewsletterWidget, LazyWidgetsOurCustomersWidget, LazyWidgetsPricingWidget, LazyWidgetsSliderWidget, LazyWidgetsTextWidget, LazyWidgetsTextWithSliderWidget, LazyWidgetsTicketsWidget, LazyWidgetsTileWithTextWidget, LazyWidgetsTilesWidget, LazyWidgetsTweetEmbedWidget, LazyWidgetsVideoWidget } from '#components'
const lazyGlobalComponents = [
  ["WidgetsArticleListWidget", LazyWidgetsArticleListWidget],
["WidgetsArtistsListWidget", LazyWidgetsArtistsListWidget],
["WidgetsBannerWithTextAndCtaWidget", LazyWidgetsBannerWithTextAndCtaWidget],
["WidgetsBlockquoteWidget", LazyWidgetsBlockquoteWidget],
["WidgetsBoxWithTextWidget", LazyWidgetsBoxWithTextWidget],
["WidgetsBulletListWidget", LazyWidgetsBulletListWidget],
["WidgetsContactFormWidget", LazyWidgetsContactFormWidget],
["WidgetsContactsWidget", LazyWidgetsContactsWidget],
["WidgetsCtaButtonWidget", LazyWidgetsCtaButtonWidget],
["WidgetsFaqWidget", LazyWidgetsFaqWidget],
["WidgetsGalleryWidget", LazyWidgetsGalleryWidget],
["WidgetsImageTextListWidget", LazyWidgetsImageTextListWidget],
["WidgetsImageWidget", LazyWidgetsImageWidget],
["WidgetsImageWithAccordionsWidget", LazyWidgetsImageWithAccordionsWidget],
["WidgetsImageWithMultipleLinksWidget", LazyWidgetsImageWithMultipleLinksWidget],
["WidgetsImageWithTextWidget", LazyWidgetsImageWithTextWidget],
["WidgetsImageWithTilesWidget", LazyWidgetsImageWithTilesWidget],
["WidgetsLatestNewsWidget", LazyWidgetsLatestNewsWidget],
["WidgetsLineUpWidget", LazyWidgetsLineUpWidget],
["WidgetsNewsletterWidget", LazyWidgetsNewsletterWidget],
["WidgetsOurCustomersWidget", LazyWidgetsOurCustomersWidget],
["WidgetsPricingWidget", LazyWidgetsPricingWidget],
["WidgetsSliderWidget", LazyWidgetsSliderWidget],
["WidgetsTextWidget", LazyWidgetsTextWidget],
["WidgetsTextWithSliderWidget", LazyWidgetsTextWithSliderWidget],
["WidgetsTicketsWidget", LazyWidgetsTicketsWidget],
["WidgetsTileWithTextWidget", LazyWidgetsTileWithTextWidget],
["WidgetsTilesWidget", LazyWidgetsTilesWidget],
["WidgetsTweetEmbedWidget", LazyWidgetsTweetEmbedWidget],
["WidgetsVideoWidget", LazyWidgetsVideoWidget],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

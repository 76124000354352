<template>
  <span class="base-icon" v-html="iconHtml" />
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ icon?: string | null }>(), {
  icon: '<svg/>'
})

const iconHtml = computed(() => props.icon ?? '<svg/>')
</script>

<style lang="scss" scoped>
.base-icon {
  display: block;
}

.base-icon:deep(svg) {
  width: 100% !important;
  height: 100% !important;

  path {
    transition: fill $transition, stroke $transition;
  }

  g {
    clip-path: unset !important;
  }
}
</style>

export default defineNuxtPlugin({
  name: 'scroll-client',
  hooks: {
    'page:finish': () => {
      const { hash } = useRoute()
      const element = hash ? document.querySelector(hash) : null
      const top = element?.getBoundingClientRect().top ?? 0

      document.scrollingElement?.scrollTo({
        top,
        left: 0,
        behavior: 'instant' as ScrollBehavior
      })
      document.body?.scrollTo({
        top,
        left: 0,
        behavior: 'instant' as ScrollBehavior
      })
    }
  }
})

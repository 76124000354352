export default defineNuxtPlugin({
  name: 'route-change',
  hooks: {
    'page:start': () => {
      document.body.classList.add('route-change')
    },
    'page:finish': () => {
      document.body.classList.remove('route-change')
    }
  }
})

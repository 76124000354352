import { sortBy } from 'lodash-es'
import type { Ref } from 'vue'

import type { PublicMainMenu } from '@/service/__generated-api'

type HeaderSettings = Omit<PublicMainMenu, 'id'>

// Ref.: https://v3.nuxtjs.org/guide/features/state-management/
export const useHeaderSettings = async (): Promise<
  Ref<HeaderSettings | null>
> => {
  const settings = useState<HeaderSettings | null>(
    'header-settings',
    () => null
  )

  if (!settings.value) {
    const headerSettings = await useApi().publicMenuMainRetrieve({
      fetchKeys: {
        items: true,
        primary_cta_button: true,
        secondary_cta_button: true,
        festival_date: true,
        festival_place: true
      }
    })

    settings.value = {
      ...headerSettings,
      items: sortBy(headerSettings.items, 'is_after_divider')
    }
  }

  return settings
}

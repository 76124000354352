<template>
  <component
    :is="baseLink ? BaseLinkComponent : tag"
    v-bind="{
      ...(baseLink && { link: baseLink, placeholder }),
      ...(to && { to }),
      ...(isDisabled && { disabled: true })
    }"
    :class="classes"
    class="button t-button"
  >
    <slot name="loader" v-bind="{ loading }">
      <LoaderIcon v-if="loading" class="loader-icon" />
    </slot>
    <span v-if="icon" class="content">
      <BaseIcon :icon="icon" class="icon" />
      <slot />
      <span class="text" v-html="nbsp(text || baseLink?.label)" />
    </span>
    <slot v-else>
      <span class="text" v-html="nbsp(text || baseLink?.label)" />
    </slot>
  </component>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router'

import { NuxtLink } from '#components'
import LoaderIcon from '@/assets/icons/naviparking/loader.svg?component'
import { PublicCtaButtonColorEnum, PublicLink } from '~/service/__generated-api'

const BaseLinkComponent = resolveComponent('BaseLink')

const props = withDefaults(
  defineProps<{
    color?: PublicCtaButtonColorEnum
    tag?: 'button' | 'span' | 'a' | typeof NuxtLink
    to?: RouteLocationRaw
    text?: string
    icon?: string
    baseLink?: PublicLink | null
    loading?: boolean
    disabled?: boolean
    placeholder?: boolean
  }>(),
  {
    color: 'primary',
    tag: 'button',
    to: undefined,
    text: '',
    icon: undefined,
    baseLink: undefined,
    disabled: false,
    loading: false,
    placeholder: false
  }
)

const isDisabled = computed(() => (props.loading ? true : props.disabled))
const classes = computed(() => [
  {
    'button--disabled': isDisabled.value,
    'button--loading': props.loading
  },
  `button--${props.color}`
])
</script>

<style lang="scss" scoped>
$button-text-color: var(--button-text-color, #{$black});
$button-bg-color: var(--button-bg-color, #{$orange});
$button-text-hover-color: var(--button-text-hover-color, #{$orange});
$button-bg-hover-color: var(--button-bg-hover-color, #{$black});
$button-border-color: var(--button-border-color, #{$orange});
$button-border-hover-color: var(--button-border-hover-color, #{$black});

@mixin btn-style(
  $bg-color,
  $bg-hover,
  $text-color: $black,
  $text-hover-color: $orange
) {
  --button-bg-color: #{$bg-color};
  --button-bg-hover-color: #{$bg-hover};
  --button-text-color: #{$text-color};
  --button-text-hover-color: #{$text-hover-color};
  --button-border-color: #{$bg-color};
  --button-border-hover-color: #{$bg-hover};
}

@mixin btn-empty-style(
  $text-color,
  $text-hover-color,
  $bg-hover-color: $black,
  $bg-color: transparent,
  $border-hover-color: $text-color
) {
  --button-bg-color: #{$bg-color};
  --button-bg-hover-color: #{$bg-hover-color};
  --button-text-color: #{$text-color};
  --button-text-hover-color: #{$text-hover-color};
  --button-border-color: #{$text-color};
  --button-border-hover-color: #{$border-hover-color};
}

.button {
  --icon-color: #{$button-text-color};
  @include center-content;
  @include font-size(
    (
      xs: 22px 0.90909,
      lg: 15px 1.33333,
      xxl: 18px 1.11111
    )
  );
  @include focus-visible;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: rem(14px) rem(22px);
  overflow: hidden;
  font-weight: 700;
  color: $button-text-color;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background-color: $button-bg-color;
  border: rem(2px) solid $button-border-color;
  transition:
    color $transition,
    background-color $transition,
    border-color $transition;

  @include media-up(lg) {
    padding: rem(10px) rem(22px);
  }

  @include media-up(xxl) {
    padding: rem(15px) rem(26px);
  }

  &:hover,
  &:focus-visible {
    --button-text-color: #{$button-text-hover-color};
    --button-bg-color: #{$button-bg-hover-color};
    --button-border-color: #{$button-border-hover-color};
  }

  &.button--disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.4;
  }

  &--primary {
    @include btn-style($orange, $black);
  }

  &--primary_light {
    @include btn-style($orange, $light-grey, $text-hover-color: $black);
  }

  &--primary_dark {
    @include btn-style($black, $light-grey, $orange, $black);
  }

  &--secondary {
    @include btn-empty-style($black, $orange);
  }

  &--secondary_light {
    @include btn-empty-style($light-grey, $black, $light-grey);
  }

  &--secondary_orange {
    @include btn-empty-style(
      $black,
      $orange,
      $black,
      $border-hover-color: $black
    );
  }

  &--loading {
    .loader-icon {
      opacity: 1;
    }
  }
}

.loader-icon {
  --loader-color: #{$primary-blue-500};
  margin-right: rem(8px);
  opacity: 0;
  transition: opacity $transition;
  animation: spinner 1s infinite linear;
}

.content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  width: rem(16px);
  height: rem(16px);
  margin-right: rem(8px);
}

@keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}
</style>

// @ts-nocheck
import qs from 'qs'

import { Api } from '~~/service/__generated-api'

export default defineNuxtPlugin(async nuxtApp => {
  const host = new URL(import.meta.env.VITE_API_CLIENT_URL).host
  const httpService = new Api({
    paramsSerializer: params => qs.stringify(params, { indices: false })
  })

  httpService.instance.interceptors.request.use(config => {
    config.headers = {
      ...config.headers,
      'Accept-Language': nuxtApp.$i18n.locale.value
    }

    if (process.server) {
      config.baseURL = import.meta.env.VITE_API_SERVER_URL
      config.headers.Host = host
      config.headers['X-Forwarded-Proto'] = 'https'
    } else {
      config.baseURL = import.meta.env.VITE_API_CLIENT_URL
      delete config.headers.Host
      delete config.headers['X-Forwarded-Proto']
    }

    return config
  })

  return {
    provide: {
      httpService,
      api: httpService.public
    }
  }
})
